export const useFormData = () => useState("FormData", () => ({
    // Custom fields only needed in the frontend
    productName: "",
    registrationNumber: "",
    maxRegistrationDays: 0,

    // Regular form fields
    productNumber: "",
    serialNumber1: "",
    serialNumber2: "",
    serialNumber3: "",
    referenceNumber: "",
    customerNumber: "",
    title: "",
    lastName: "",
    firstName: "",
    secondName: "",
    birthDate: null,
    address: {
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        postalCode: ""
    },
    email: "",
    phone: "",
    propertyTypeId: 0,
    contractTypeId: 0,
    areasOfInterests: [],
    scheppachMachinesOwned: "",
    machineTypeInterest: [],
    newsletterContact: false,
    seller: "",
    monthBuild: 0,
    yearBuild: 0,
    purchaseDate: null
}));

type StepState = {
    currentStep: number,
    readonly maxSteps: number
}
export const useStep = () => useState<StepState>("StepState", () => ({
    currentStep: 1,
    maxSteps: 6,
}));