<script setup>
import { ref } from 'vue';

const props = defineProps({
    label: String,
    options: Array,
    modelValue: [String, Number],
    placeholder: String,
    initialValue: [String, Number],
    initialIndex: Number,
    onChanged: Function,
    required: Boolean,
    requiredErrorText: String,
    validationFailedText: String,
    validationMethod: Function,
});

const emit = defineEmits(['update:modelValue']);
const invalid = ref(false);
const validationFailed = ref(false);
const dropdownOpen = ref(false);
const dropdownRef = ref(null);

const isOptionObject = (option) => {
    return option !== null && typeof option === 'object' && 'text' in option && 'value' in option;
};

const isOptionListObjects = computed(() => {
    return Array.isArray(props.options) && props.options.length > 0 && isOptionObject(props.options[0]);
});

const getOptionValue = (option) => {
    return isOptionListObjects.value ? option.value : option;
};

const getOptionText = (option) => {
    return isOptionListObjects.value ? option.text : option;
};


// Determine initial selection based on initialIndex or initialValue
const determineInitialSelection = () => {
    if (typeof props.initialIndex === 'number' && props.options[props.initialIndex] !== undefined) {
        return getOptionValue(props.options[props.initialIndex]);
    } else if (props.initialValue != null) {
        return props.initialValue;
    }
    return null;
};

const onClose = () => {
    checkValidity(selectedOption.value);
};

const selectedOption = ref(determineInitialSelection());

const checkValidity = (value) => {
    invalid.value = false;
    validationFailed.value = false;

    if (props.required && (!value && value !== 0)) {
        invalid.value = true;
        return;
    }

    if (props.validationMethod && !props.validationMethod(value)) {
        validationFailed.value = true;
    }
};

// Emit the initial value if it's provided
if (props.initialValue != null) {
    emit('update:modelValue', props.initialValue);
}

const isPlaceholderShown = computed(() => {
    return selectedOption.value == null && props.placeholder;
});

const displayText = computed(() => {
    if (selectedOption.value == null) {
        return props.placeholder || "";
    }

    if (isOptionListObjects.value) {
        const selected = props.options.find(option => option.value === selectedOption.value);
        return selected ? selected.text : selectedOption.value;
    } else {
        return selectedOption.value;
    }
});

const selectOption = (event, option) => {
    event.stopPropagation();
    const value = getOptionValue(option);
    selectedOption.value = value;
    emit('update:modelValue', value);
    dropdownOpen.value = false;

    onClose();
    // Also pass the new value/option to onchanged
    props.onChanged?.call(null, getOptionValue(option));
};

const toggleDropdown = (event) => {
    event.stopPropagation();
    dropdownOpen.value = !dropdownOpen.value;

    if (dropdownOpen.value === false) {
        onClose();
    }
};

const closeDropdown = (event) => {
    if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
        dropdownOpen.value = false;
        onClose();
    }
};

onMounted(() => {
    window.addEventListener('click', closeDropdown);
});

onUnmounted(() => {
    window.removeEventListener('click', closeDropdown);
});
</script>

<template>
    <div class="custom-dropdown" ref="dropdownRef" @click="toggleDropdown">
        <div v-if="label" :class="{ 'error-text': invalid || validationFailed }" class="label">
            {{ label }}{{ required ? ' *' : '' }}
        </div>
        <div
            :class="['display-area', { 'placeholder': isPlaceholderShown }, { 'error-border': invalid || validationFailed }, { 'error-text': invalid || validationFailed }]">

            {{ displayText }}
        </div>
        <ul v-if="dropdownOpen" class="options-list">
            <li v-for="option in options" :key="getOptionValue(option)"
                :class="{ 'selected-option': selectedOption === getOptionValue(option) }"
                @click="ev => selectOption(ev, option)">
                {{ getOptionText(option) }}
            </li>
        </ul>
        <STooltip :show="invalid && requiredErrorText != null" textColor="white" backgroundColor="#E26C5F">
            {{ requiredErrorText }}
        </STooltip>
        <STooltip :show="validationFailed && validationFailedText != null" textColor="white" backgroundColor="#E26C5F">
            {{ validationFailedText }}
        </STooltip>
    </div>
</template>

<style scoped>
.custom-dropdown {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.display-area {
    padding: 14px;
    border: none;
    border-bottom: 1px solid var(--scheppach-primary-300);
    background-color: var(--scheppach-primary-50);
    position: relative;
    min-height: 51px;
    display: flex;
    align-items: center;
}

.error-text {
    color: var(--scheppach-error-500);
}

.error-border {
    border-bottom: 2px solid var(--scheppach-error-500);
}

.display-area::after {
    /* Down arrow symbol */
    content: '▼';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.display-area:not(.error-text)::after {
    /* Down arrow symbol */
    color: var(--scheppach-primary-500);
}

.options-list {
    position: absolute;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    border: none;
    border-top: 1px solid var(--scheppach-primary-500);
    background-color: var(--scheppach-shades-0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    max-height: 160px;
    overflow-y: auto;
}

.options-list li {
    padding: 10px;
    border-bottom: 1px solid var(--scheppach-neutral-200);
}

.options-list li:hover {
    background-color: var(--scheppach-primary-300);
    color: var(--scheppach-shades-0);
}

.selected-option {
    background-color: var(--scheppach-primary-50);
    font-weight: bold;
}

.placeholder:not(.error-text) {
    color: var(--scheppach-primary-500);
}

.placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
}
</style>
