<template>
    <div class="flex justify-center min-w-full">
        <div class="w-screen max-w-6xl">
            <img src="/scheppach_diy_head_online_garantie.svg" class="w-full" alt="">
            <div class="flex justify-center items-center h-full w-full p-6 text-white"
                style="height:75vh; background: linear-gradient(160deg, #004884, #002b50);">
                <div class="text-start">
                    <p class="font-bold text-xl lg:text-4xl mb-8">
                        {{ $t('registration_success') }}
                    </p>
                    <div class="grid grid-cols-2 gap-2">
                        <p class="font-bold mb-4 lg:text-xl">
                            {{ $t('registration_number') }}:
                        </p>
                        <p>
                            {{ form.registrationNumber }}
                        </p>
                    </div>
                    <div class="grid grid-cols-2 gap-2">
                        <p class="font-bold mb-4 lg:text-xl">
                            {{ $t('product') }}:
                        </p>
                        <p>
                            {{ form.productName }} ({{ form.productNumber }})
                        </p>
                    </div>
                    <p class="mt-8 lg:text-xl">
                        {{ $t('you_will_reveive_email') }}: {{ form.email }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useFormData } from "../composables/appState";

const form = useFormData();

</script>