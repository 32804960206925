<template>
    <button type="button" class="toggle-button" :class="{ 'active': isActive }" @click="toggleState">
        <slot></slot>
    </button>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
    value: [String, Number, Object],
    array: Array,
    active: Boolean,
    onClick: Function
});

const isActive = ref(props.active);

watch(() => props.active, (newActive) => {
    isActive.value = newActive;
});

const toggleState = () => {
    isActive.value = !isActive.value;
    if (isActive.value) {
        if (!props.array.includes(props.value)) {
            props.array.push(props.value);
        }
    } else {
        const index = props.array.indexOf(props.value);
        if (index > -1) {
            props.array.splice(index, 1);
        }
    }

    // Call the custom onClick function if it's provided
    if (props.onClick) {
        props.onClick();
    }
};
</script>

<style scoped>
.toggle-button {
    padding: 8px 16px;
    font-weight: 600;
    color: var(--scheppach-primary-500);
    background-color: var(--scheppach-shades-0);
    border: 1px solid var(--scheppach-primary-500);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.toggle-button.active {
    color: var(--scheppach-shades-0);
    background-color: var(--scheppach-primary-500);
    border: 1px solid transparent;
}

.toggle-button:hover {
    opacity: 0.8;
}

.toggle-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--scheppach-primary-300);
}
</style>
