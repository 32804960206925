<template>
    <div v-if="show" class="tooltip"
        :style="{ color: textColor, backgroundColor: backgroundColor, '--tooltip-background': backgroundColor, '--tooltip-border-color': borderColor }">
        <slot></slot>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    show: Boolean,
    textColor: String, // Prop for text color
    backgroundColor: String // Prop for background color
});

// Function to create a darker shade
function darken(color, percent) {
    if (color == null) return;
    const f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = f >> 8 & 0x00FF,
        B = f & 0x0000FF;
    return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
}

const borderColor = computed(() => darken(props.backgroundColor, -0.2)); // Darken by 20%
</script>
  
<style scoped>
.tooltip {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 10;
    background: var(--scheppach-neutral-50);
    border-radius: 4px;
    padding: 4px 8px;
    margin-top: 12px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    border: 1px solid var(--scheppach-neutral-200);
    max-width: 75%;
}

.tooltip::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 15px;
    margin-left: -13px;
    border-width: 13px;
    border-style: solid;
    border-color: transparent transparent var(--scheppach-neutral-50) transparent;
    border-bottom-color: var(--scheppach-neutral-200);
}
</style>








