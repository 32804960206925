<script setup>
import { ref } from 'vue';

// Define the props
const props = defineProps({
    label: String,
    modelValue: [String, Number],
    required: Boolean,
    requiredErrorText: String,
    validationFailedText: String,
    validationMethod: Function,

    minDate: {
        type: String,
        default: null
    },
    maxDate: {
        type: String,
        default: null
    },
});

const emit = defineEmits(["update:modelValue"]);
const invalid = ref(false);
const validationFailed = ref(false);

const minDateISO = computed(() => {
    return props.minDate ? convertToISODate(props.minDate) : null;
});

const maxDateISO = computed(() => {
    return props.maxDate ? convertToISODate(props.maxDate) : null;
});

const onInput = (event) => {
    emit("update:modelValue", event.target.value);
    checkValidity(event.target.value);
};

const onBlur = () => {
    checkValidity(props.modelValue);
};

const onFocus = () => {
    invalid.value = false;
    validationFailed.value = false;
};

const checkValidity = (value) => {
    // Reset validation flags
    invalid.value = false;
    validationFailed.value = false;

    // Check for required field
    if (props.required && (!value || value.trim() === '')) {
        invalid.value = true;
        return;
    }

    // Date validation
    const inputValue = new Date(value);
    const minDateValue = props.minDateDDMMYYYY ? new Date(convertToISODate(props.minDateDDMMYYYY)) : null;
    const maxDateValue = props.maxDateDDMMYYYY ? new Date(convertToISODate(props.maxDateDDMMYYYY)) : null;

    if (minDateValue && inputValue < minDateValue) {
        validationFailed.value = true;
        return;
    }
    if (maxDateValue && inputValue > maxDateValue) {
        validationFailed.value = true;
        return;
    }

    // Run custom validation method if provided
    if (props.validationMethod && !props.validationMethod(value)) {
        validationFailed.value = true;
    }
};

const convertToISODate = (dateStr) => {
    // Truncate to first 10 characters to remove any timestamp
    const truncatedDateStr = dateStr.substring(0, 10);

    const parts = truncatedDateStr.split(/[-./]/);
    if (parts.length === 3) {
        // Check if the format is YYYY-MM-DD or similar
        if (parts[0].length === 4) {
            return `${parts[0]}-${parts[1].padStart(2, '0')}-${parts[2].padStart(2, '0')}`;
        }
        // Otherwise, assume DD-MM-YYYY or similar format
        return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
    }
    return null;
};


</script>

<template>
    <div class="text-box-container">
        <label v-if="label" :for="label" :class="{ 'error-text': invalid || validationFailed }" class="label">
            {{ label }}{{ required ? ' *' : '' }}
        </label>

        <input :id="label" type="date" :value="modelValue" @input="onInput" @blur="onBlur" @focus="onFocus"
            :min="minDateISO" :max="maxDateISO" :required="required"
            :class="{ 'error-border': invalid || validationFailed }" class="input-field">

        <STooltip :show="invalid && requiredErrorText != null" textColor="white" backgroundColor="#E26C5F">
            {{ requiredErrorText }}
        </STooltip>
        <STooltip :show="validationFailed && validationFailedText != null" textColor="white" backgroundColor="#E26C5F">
            {{ validationFailedText }}
        </STooltip>
    </div>
</template>

<style scoped>
/* Note: style is exactly the same for: SDateInput, SEmailInput, SNumberInput and STextInput */
.text-box-container {
    position: relative;
    width: 100%;
}

.label {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: var(--scheppach-neutral-700);
}

.input-field {
    width: 100%;
    height: 51px;
    padding: 0px 14px;
    border: none;
    border-bottom: 1px solid var(--scheppach-notification-700);
    background-color: var(--scheppach-primary-50);
    box-shadow: none;
    outline: none;
}

.input-field.error-border {
    color: var(--scheppach-error-500);
}

/* Style for the error state */
.error-border {
    border-bottom: 2px solid var(--scheppach-error-500);
}

/* Style for the input field when focused, without error */
.input-field:focus:not(.error-border) {
    border-bottom: 2px solid var(--scheppach-notification-70);
    box-shadow: 0 -1px 0 0 var(--scheppach-neutral-50), -1px 0 0 0 var(--scheppach-neutral-50), 1px 0 0 0 var(--scheppach-neutral-50);
}

.input-field.error-border::placeholder {
    color: var(--scheppach-error-500);
}

.input-field::placeholder {
    color: var(--scheppach-primary-500);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
}

.error-text {
    /* Red text for error */
    color: var(--scheppach-error-500);
}
</style>