<template>
    <div class="transition-container">
        <transition :name="computedTransitionName">
            <slot />
        </transition>
    </div>
</template>
  
<script setup>
import { ref, watch, computed } from 'vue';

const props = defineProps({
    currentStep: Number
});

const prevStep = ref(props.currentStep); // Initialize with the current step

// Update prevStep whenever currentStep changes
watch(() => props.currentStep, (newVal, oldVal) => {
    prevStep.value = oldVal;
}, { immediate: false });

const computedTransitionName = computed(() => {
    return props.currentStep > prevStep.value ? 'slide-left' : 'slide-right';
});
</script>
  
<style scoped>
.transition-container {
    position: relative;
    min-height: 400px;
    overflow: hidden;
}

/* Common styles for entering and leaving elements */
.slide-left-enter-active,
.slide-right-leave-active,
.slide-right-enter-active,
.slide-left-leave-active {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

/* Left slide (next step) animations */
.slide-left-enter-active {
    z-index: 2;
    animation: slideLeftInFadeIn 0.15s ease;
}

.slide-left-leave-active {
    z-index: 1;
    animation: slideLeftOutFadeOut 0.15s ease;
}

@keyframes slideLeftInFadeIn {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    20% {
        /* Delayed start for fade-in */
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideLeftOutFadeOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    80% {
        /* Extended duration for fade-out */
        opacity: 1;
    }

    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

/* Right slide (previous step) animations */
.slide-right-enter-active {
    z-index: 2;
    animation: slideRightInFadeIn 0.15s ease;
}

.slide-right-leave-active {
    z-index: 1;
    animation: slideRightOutFadeOut 0.15s ease;
    /* Slightly longer duration */
}

@keyframes slideRightInFadeIn {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    20% {
        /* Delayed start for fade-in */
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideRightOutFadeOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    80% {
        /* Extended duration for fade-out */
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}
</style>

  