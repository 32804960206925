<template>
    <button :type="type" class="custom-button" @click="onClick">
        <slot></slot>
    </button>
</template>

<script setup>

const props = defineProps({
    type: {
        type: String,
        default: 'button' // Default type is 'button'
    }
});

const emit = defineEmits(["click"]);

const onClick = (event) => {
    emit("click", event);
};
</script>

<style scoped>
.custom-button {
    padding: 8px 16px;
    font-weight: 600;
    color: var(--scheppach-shades-0);
    background-color: var(--scheppach-primary-500);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.custom-button:hover {
    opacity: 0.8;
}

.custom-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--scheppach-primary-300);
}

.custom-button:disabled {
    opacity: 0.5;
}
</style>
  