<script setup>
import { useFormData, useStep } from "../composables/appState";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const step = useStep();
const form = useFormData();

step.value.currentStep = 1;

useSeoMeta({
    title: t('page_title'),
    ogTitle: t('page_title'),
    description: t('page_description'),
    ogDescription: t('page_description'),
    // ogImage: 'https://example.com/image.png',
    // twitterCard: 'summary_large_image',
})
</script>

<template>
    <div class="mt-8 ml-8 mr-8 mb-20">
            <SStepIndicator :currentStep="step.currentStep" :totalSteps="step.maxSteps"
                :stepsTitles='[$t("warranty_conditions"), $t("product_registration"), $t("product_details"), $t("customer_details"), $t("customer_interests"), $t("registration_finish")]' />

            <STransitionStepper :currentStep="step.currentStep">
                <div v-if="step.currentStep === 1" class="m-6">
                    <StepWarrantyConditions />
                </div>
                <div v-if="step.currentStep === 2" class="m-6">
                    <StepRegistrationStart />
                </div>
                <div v-else-if="step.currentStep === 3" class="m-6">
                    <StepProductDetails />
                </div>
                <div v-else-if="step.currentStep === 4" class="m-6">
                    <StepCustomerDetails />
                </div>

                <div v-else-if="step.currentStep === 5" class="m-6">
                    <StepCustomerInterests />
                </div>

                <div v-else-if="step.currentStep === 6" class="m-6">
                    <StepFinish />
                </div>
            </STransitionStepper>
            <!-- <SButton @click="() => console.log(form)">console.log(form.value)</SButton> -->
    </div>
</template>

<style>
/* */
</style>
