<script setup>
import { useFormData, useStep } from "../composables/appState";

const { t, locale } = useI18n()

const step = useStep();
const form = useFormData();

let isUpdating = ref(false);
let isToastVisible = ref(false);
let errorToastMessage = ref("")

const isButtonEnabled = computed(() => {
    return form.value.serialNumber1 && form.value.serialNumber2 && form.value.monthBuild && form.value.yearBuild && form.value.purchaseDate;
});

function incStep() {
    try {
        isUpdating = true;

        const success = submit();
        if (!success) {
            return;
        }

        if (step.value.currentStep < step.value.maxSteps) {
            step.value.currentStep++;
        }
    } finally {
        isUpdating = false;
    }
}

function decStep() {
    if (step.value.currentStep > 1) {
        step.value.currentStep--;
    }
}

function submit() {
    const success = checkPurchaseDate();
    if (!success) {
        errorToastMessage.value = t('max_registration_days_exceeded');
        isToastVisible.value = true;
        return false;
    }
    return true;
}

function generateYearArrayFrom(from) {
    let years = [];
    const currentYear = new Date().getFullYear();
    for (let year = 2020; year <= currentYear; year++) {
        years.push({ text: year.toString(), value: year.toString() });
    }
    return years;
}

function checkPurchaseDate() {
    const currentDate = new Date();
    const purchaseDate = new Date(form.value.purchaseDate);

    const timeDifference = currentDate.getTime() - purchaseDate.getTime();

    const daysDifference = timeDifference / (1000 * 3600 * 24);

    return daysDifference <= form.value.maxRegistrationDays;
}

const months = computed(() => [
    { text: t("month_jan"), value: 1 },
    { text: t("month_feb"), value: 2 },
    { text: t("month_mar"), value: 3 },
    { text: t("month_apr"), value: 4 },
    { text: t("month_may"), value: 5 },
    { text: t("month_jun"), value: 6 },
    { text: t("month_jul"), value: 7 },
    { text: t("month_aug"), value: 8 },
    { text: t("month_sep"), value: 9 },
    { text: t("month_oct"), value: 10 },
    { text: t("month_nov"), value: 11 },
    { text: t("month_dec"), value: 12 }
]);

const years = generateYearArrayFrom(2020);

</script>

<template>
    <SToast v-model:isVisible="isToastVisible" bgColor="#fee2e2" textColor="#dc2626" :text="errorToastMessage"
        :duration="6000" closeable />

    <!-- <SCard> -->
    <!-- Produktdetails -->
    <section class="mb-8 h-full">
        <h3 class="text-xl font-semibold mb-3" style="color: #005188;">
            {{ $t("registration_for") }} {{ form.productName }} ({{ form.productNumber }})
        </h3>
        <form @submit.prevent="submitForm" class="space-y-4">
            <SNumberInput :label="$t('customer_number')" v-model="form.customerNumber" />
            <SNumberInput :label="$t('reference_number')" v-model="form.referenceNumber" />

            <!-- Seriennummer -->
            <div>
                <div>
                    <h2 class="block text-base font-medium text-gray-700">{{ $t("serial_number") }}</h2>
                </div>
                <div class="grid grid-flow-col auto-cols-min gap-2 items-baseline">
                    <SNumberInput class="min-w-[100px]" placeholder="0000" :min-length="4" :max-length="4"
                        v-model="form.serialNumber1" required />
                    <span>-</span>
                    <SNumberInput class="min-w-[125px]" placeholder="0000000" :min-length="5" :max-length="12"
                        v-model="form.serialNumber2" required />
                    <span>-</span>
                    <STextInput class="min-w-[40px]" placeholder="A" :min-length="1" :max-length="1"
                        v-model="form.serialNumber3" />
                </div>
            </div>

            <SDropdown :label="$t('month_build')" :options="months" v-model="form.monthBuild" required />
            <SDropdown :label="$t('year_build')" :options="years" v-model="form.yearBuild" required />
            <SDateInput :label="$t('purchase_date')" inputType="date" :max-date="new Date().toJSON()"
                v-model="form.purchaseDate" required />
            <STextInput :label="$t('bought_from')" inputType="text" v-model="form.seller" />

        </form>
        <div class="mt-8">
            <div class="flex flex-row justify-between">
                <SButton @click="decStep">
                    <SMdiIcon>arrow_back</SMdiIcon>
                </SButton>
                <SButton @click="incStep" :disabled="!isButtonEnabled || isUpdating">
                    <SMdiIcon>arrow_forward</SMdiIcon>
                </SButton>
            </div>
        </div>
    </section>
    <!-- </SCard> -->
</template>