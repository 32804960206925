<script setup>
import { ref } from 'vue';

// Define the props
const props = defineProps({
    label: String,
    modelValue: [String, Number],
    required: Boolean,
    requiredErrorText: String,
    validationFailedText: String,
    validationMethod: Function,
    placeholder: String,

    minLength: {
        type: [Number, String],
        default: null
    },
    maxLength: {
        type: [Number, String],
        default: null
    }
});

const emit = defineEmits(["update:modelValue"]);
const invalid = ref(false);
const validationFailed = ref(false);

const computedPlaceholder = computed(() => {
    if (props.placeholder == null) {
        return "";
    }
    return props.required ? `${props.placeholder} *` : props.placeholder;
});

const onInput = (event) => {
    let inputValue = event.target.value;

    // Max length restriction
    if (props.maxLength !== null && inputValue.length > props.maxLength) {
        inputValue = inputValue.substring(0, props.maxLength);
        event.target.value = inputValue;
    }

    emit("update:modelValue", inputValue);

    checkValidity(inputValue);
};

const onBlur = () => {
    checkValidity(props.modelValue);
};

const onFocus = () => {
    invalid.value = false;
    validationFailed.value = false;
};

const checkValidity = (value) => {
    // Reset validation flags
    invalid.value = false;
    validationFailed.value = false;

    // Check for required field
    if (props.required && (!value || value.trim() === '')) {
        invalid.value = true;
        return;
    }

    // Length validation
    const stringValue = value.toString();
    if ((props.minLength !== null && stringValue.length < props.minLength) ||
        (props.maxLength !== null && stringValue.length > props.maxLength)) {
        validationFailed.value = true;
        return;
    }

    // Min/Max validation
    const numValue = parseFloat(value);
    if (isNaN(numValue) && props.required) {
        validationFailed.value = true;
        return;
    }
    if (props.min !== null && numValue < props.min) {
        validationFailed.value = true;
        return;
    }
    if (props.max !== null && numValue > props.max) {
        validationFailed.value = true;
        return;
    }

    // Run custom validation method if provided
    if (props.validationMethod && !props.validationMethod(value)) {
        validationFailed.value = true;
    }
};
</script>

<template>
    <div class="text-box-container">
        <label v-if="label" :for="label" :class="{ 'error-text': invalid || validationFailed }" class="label">
            {{ label }}{{ required ? ' *' : '' }}
        </label>

        <input :id="label" type="number" :value="modelValue" @input="onInput" @blur="onBlur" @focus="onFocus"
            :required="required" :placeholder="computedPlaceholder" :class="{ 'error-border': invalid || validationFailed }"
            class="input-field" :minlength="minLength" :maxlength="maxLength">

        <STooltip :show="invalid && requiredErrorText != null" textColor="white" backgroundColor="#E26C5F">
            {{ requiredErrorText }}
        </STooltip>
        <STooltip :show="validationFailed && validationFailedText != null" textColor="white" backgroundColor="#E26C5F">
            {{ validationFailedText }}
        </STooltip>
    </div>
</template>

<style scoped>
/* Note: style is exactly the same for: SDateInput, SEmailInput, SNumberInput and STextInput */
.text-box-container {
    position: relative;
    width: 100%;
}

.label {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: var(--scheppach-neutral-700);
}

.input-field {
    width: 100%;
    height: 51px;
    padding: 0px 14px;
    border: none;
    border-bottom: 1px solid var(--scheppach-notification-700);
    background-color: var(--scheppach-primary-50);
    box-shadow: none;
    outline: none;
}

.input-field.error-border {
    color: var(--scheppach-error-500);
}

/* Style for the error state */
.error-border {
    border-bottom: 2px solid var(--scheppach-error-500);
}

/* Style for the input field when focused, without error */
.input-field:focus:not(.error-border) {
    border-bottom: 2px solid var(--scheppach-notification-70);
    box-shadow: 0 -1px 0 0 var(--scheppach-neutral-50), -1px 0 0 0 var(--scheppach-neutral-50), 1px 0 0 0 var(--scheppach-neutral-50);
}

.input-field.error-border::placeholder {
    color: var(--scheppach-error-500);
}

.input-field::placeholder {
    color: var(--scheppach-primary-500);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
}

.error-text {
    /* Red text for error */
    color: var(--scheppach-error-500);
}
</style>