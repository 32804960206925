<script setup>
import { useFormData, useStep } from "../../composables/appState";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const step = useStep();
const form = useFormData();


let isUpdating = ref(false);

const titleOptions = [
    { text: t('mrs'), value: t('mrs') },
    { text: t('mr'), value: t('mr') },
    { text: t('diverse'), value: t('diverse') },
]

const isButtonEnabled = computed(() => {
    return form.value.title && form.value.firstName && form.value.lastName && form.value.address.streetAddress && form.value.address.postalCode && form.value.address.city && form.value.email;
});

function incStep() {
    try {
        isUpdating = true;
        if (step.value.currentStep < step.value.maxSteps) {
            step.value.currentStep++;
        }
    } finally {
        isUpdating = false;
    }
}

function decStep() {
    if (step.value.currentStep > 1) {
        step.value.currentStep--;
    }
}

</script>

<template>
    <!-- <SCard> -->
    <section class="mb-8">
        <h3 class="text-xl font-semibold mb-3" style="color: #005188;">{{ $t('customer_details') }}</h3>
        <form @submit.prevent="submitForm" class="space-y-4">

            <!-- Anrede -->
            <SDropdown v-model="form.title" :options="titleOptions" :label="$t('title')" required
                :placeholder="$t('please_select')" />

            <!-- Name -->
            <div class="grid grid-cols-2 gap-3">
                <STextInput :label="$t('first_name')" v-model="form.firstName" required />
                <STextInput :label="$t('last_name')" v-model="form.lastName" required requiredErrorText="requiredErrorText"
                    labelTransition />
            </div>

            <!-- Adresse -->
            <STextInput :label="$t('street_and_number')" v-model="form.address.streetAddress" required />

            <div class="grid grid-cols-2 gap-3">
                <SNumberInput :label="$t('postal_code')" v-model="form.address.postalCode" required />
                <STextInput :label="$t('city')" v-model="form.address.city" required />
            </div>

            <!-- E-Mail und Telefon -->
            <SEmailInput :label="$t('email')" v-model="form.email" required />

            <SNumberInput :label="$t('phone')" v-model="form.phone" />

            <!-- Geburtsdatum -->
            <SDateInput :label="$t('birthday')" v-model="form.birthDate" />
        </form>
    </section>

    <div class="mt-8">
        <div class="flex flex-row justify-between">
            <SButton @click="decStep">
                <SMdiIcon>arrow_back</SMdiIcon>
            </SButton>
            <SButton @click="incStep" :disabled="!isButtonEnabled || isUpdating">
                <SMdiIcon>arrow_forward</SMdiIcon>
            </SButton>
        </div>
    </div>
    <!-- </SCard> -->
</template>

<style scoped>
/* ... */
</style>
  