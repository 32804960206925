<template>
    <div v-if="isVisible" :style="toastStyle" class="toast">
        {{ text }}
        <button v-if="closeable" class="close-btn" @click="closeToast">&times;</button>
    </div>
</template>
  
  
<script setup>

const props = defineProps({
    isVisible: Boolean,
    bgColor: String,
    textColor: String,
    text: String,
    duration: Number,
    closeable: Boolean
});

const emits = defineEmits(["update:isVisible"]);
const toastTimeout = ref(null);

const toastStyle = computed(() => ({
    backgroundColor: props.bgColor,
    color: props.textColor,
    position: "fixed",
    bottom: "20px",
    right: "20px",
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "opacity 0.5s",
    zIndex: 1000,
    minWidth: "350px",
    maxWidth: "80vh",
    wordWrap: "break-word"
}));

function closeToast() {
    if (toastTimeout.value) {
        clearTimeout(toastTimeout.value);
        toastTimeout.value = null;
    }
    emits("update:isVisible", false);
}

watch(() => props.isVisible, (newValue) => {
    if (newValue && props.duration > 0) {
        if (toastTimeout.value) {
            clearTimeout(toastTimeout.value);
        }
        toastTimeout.value = setTimeout(() => {
            closeToast();
        }, props.duration);
    }
});

onUnmounted(() => {
    if (toastTimeout.value) {
        clearTimeout(toastTimeout.value);
    }
});
</script>
  
<style scoped>
.toast {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    position: relative;
    gap: 1rem;
}

.close-btn {
    background: none;
    border: none;
    color: inherit;
    font-size: 1.2em;
    cursor: pointer;
    font-size: 24px;
}
</style>
  