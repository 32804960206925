<script setup>
import { useFormData, useStep } from "../composables/appState";

const step = useStep();
const form = useFormData();

const { t, locale } = useI18n()


const isError = ref(false);
const isUpdating = ref(false);

let isToastVisible = ref(false);
let errorToastMessage = ref("")

const machineOptions = [
    { text: "0", value: "0" },
    { text: "1-3", value: "1-3" },
    { text: "3-5", value: "3-5" },
    { text: "5+", value: "5+" },
];

async function fetchAreasOfInterest() {
    try {
        isUpdating.value = true;
        const response = await fetch(`/api/getAreasOfInterest`);
        const data = await response.json();
        return data;
    } catch (error) {
        isError.value = true;
        console.error('Failed to fetch areas of interest:', error);
    } finally {
        isUpdating.value = false;
    }
}

async function fetchMachineTypeInterest() {
    try {
        isUpdating.value = true;
        const response = await fetch(`api/getMachineTypeInterest`);
        const data = await response.json();
        return data;
    } catch (error) {
        isError.value = true;
        console.error('Failed to fetch areas of interest:', error);
    } finally {
        isUpdating.value = false;
    }
}

async function fetchPropertyTypes() {
    try {
        isUpdating.value = true;
        const response = await fetch(`api/getPropertyTypes`);
        const data = await response.json();
        return data;
    } catch (error) {
        isError.value = true;
        console.error('Failed to fetch areas of interest:', error);
    } finally {
        isUpdating.value = false;
    }
}

async function fetchContractTypes() {
    try {
        isUpdating.value = true;
        const response = await fetch(`api/getContractTypes`);
        const data = await response.json();
        return data;
    } catch (error) {
        isError.value = true;
        console.error('Failed to fetch areas of interest:', error);
    } finally {
        isUpdating.value = false;
    }
}

const translateTypes = (types) => {
    const shortLocale = locale.value.substring(0, 2);
    return types.map(type => ({
        text: type.description[shortLocale],
        value: type.id
    }));
};

const areasOfInterests = ref([]);
const machineTypeInterest = ref([]);
const contractTypes = ref([]);
const propertyTypes = ref([]);

const contractTypesTranslated = computed(() => translateTypes(contractTypes.value));
const propertyTypesTranslated = computed(() => translateTypes(propertyTypes.value));

onMounted(async () => {
    areasOfInterests.value = await fetchAreasOfInterest();
    machineTypeInterest.value = await fetchMachineTypeInterest();
    contractTypes.value = await fetchContractTypes();
    propertyTypes.value = await fetchPropertyTypes();
});

async function incStep() {
    if (step.value.currentStep < step.value.maxSteps) {
        const success = await submit();
        if (success) {
            step.value.currentStep++;
        }
    }
}

function decStep() {
    if (step.value.currentStep > 1) {
        step.value.currentStep--;
    }
}

async function submit() {
    try {
        isUpdating.value = true;

        const response = await fetch(`api/registerProductForCustomer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form.value),
        });

        const responseData = await response.json();

        if (responseData === null || !response.ok) {
            // error
            errorToastMessage.value = responseData.message;
            if (response.status === 404) {
                errorToastMessage.value = t('product_not_found_error') + " service@scheppach.com.";
            }
            if (!errorToastMessage.value) {
                errorToastMessage.value = t('undefined_error') + " service@scheppach.com.";
            }
            isToastVisible.value = true;
            return false;
        } else if (response.ok) {
            form.value.registrationNumber = responseData.registrationNumber;
            form.value.productNumber = responseData.productNumber;
            form.value.productName = responseData.productName;
            return true;
        } else {
            return false;
        }
    } catch (error) {
        // error
        console.log("catch error")
        console.error("Error RegisterProductForCustomer:", error);
        errorToastMessage.value = t('undefined_error') + " service@scheppach.com.";
        isToastVisible.value = true;
        return false;
    } finally {
        isUpdating.value = false;
    }
}
</script>

<template>
    <SToast v-model:isVisible="isToastVisible" bgColor="#fee2e2" textColor="#dc2626" :text="errorToastMessage"
        :duration="6000" closeable />

    <!-- <SCard> -->
    <section class="mb-8">
        <template v-if="isUpdating">
            <h2>{{ $t('loading') }}...</h2>
        </template>
        <template v-else-if="isError">
            <div class="rounded bg-[#fee2e2] p-4">
                <h2 class="text-red-500 font-bold">{{ $t('undefined_error') }} service@scheppach.com.</h2>
            </div>
        </template>
        <template v-else>
            <h3 class="text-xl font-semibold mb-3" style="color: #005188;">{{ $t('customer_interests') }}</h3>
            <form @submit.prevent="submitForm" class="space-y-4">
                <!-- Wohnverhältnis -->
                <!-- Typ -->
                <SDropdown v-model="form.propertyTypeId" :options="propertyTypesTranslated" :label="$t('living_situation')"
                    :placeholder-value="0" :placeholder="$t('please_select')" />

                <!-- Vertragsart -->
                <SDropdown v-model="form.contractTypeId" :options="contractTypesTranslated" :label="$t('contract_type')"
                    :placeholder-value="0" :placeholder="$t('please_select')" />

                <!-- Interessensbereiche (Areas of Interest) -->
                <div>
                    <p class="block text-md font-medium text-gray-700">{{ $t('area_of_interest') }}</p>
                    <div class="flex flex-row flex-wrap justify-center md:justify-start gap-2">
                        <template v-for="areaInterest in areasOfInterests" :key="areaInterest.id">
                            <SToggleButton :value="areaInterest.id" :array="form.areasOfInterests">
                                {{
                                    locale === "fr-FR"
                                    ? areaInterest.description.fr
                                    : locale === "en-US"
                                        ? areaInterest.description.en
                                        : areaInterest.description.de
                                }}
                            </SToggleButton>
                        </template>
                    </div>
                </div>

                <!-- Besitz von Scheppach-Geräten -->
                <SDropdown v-model="form.scheppachMachinesOwned" :options="machineOptions"
                    :label="$t('scheppach_machines_owned')" :placeholder="$t('please_select')" />

                <!-- Interesse an Gerätetypen -->
                <div>
                    <label class="block text-md font-medium text-gray-700">{{ $t('machine_type_interest') }}</label>
                    <div class="flex flex-row flex-wrap justify-center md:justify-start gap-4">
                        <template v-for="machineType in machineTypeInterest" :key="machineType.id">
                            <SToggleButton :value="machineType.id" :array="form.machineTypeInterest">
                                {{
                                    locale === "fr-FR"
                                    ? machineType.description.fr
                                    : locale === "en-US"
                                        ? machineType.description.en
                                        : machineType.description.de
                                }}
                            </SToggleButton>
                        </template>
                    </div>
                </div>

                <!-- Zustimmung zu Marketing-Informationen -->
                <div>
                    <label class="block text-md font-medium text-gray-700">{{ $t('newsletter_question') }}</label>
                    <div class="mt-1 flex items-center">
                        <input type="checkbox" v-model="form.futurecontact" id="futurecontact"
                            class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded">
                        <label for="futurecontact" class="ml-2 block text-sm text-gray-900">
                            {{ $t('newsletter_answer') }}
                        </label>
                    </div>
                </div>
            </form>
        </template>
    </section>
    <div class="mt-8">
        <div class="flex flex-row justify-between">
            <SButton @click="decStep">
                <SMdiIcon>arrow_back</SMdiIcon>
            </SButton>
            <SButton @click="incStep" :disabled="isUpdating || isError">
                {{ $t('finish_registration_now') }}
            </SButton>
        </div>
    </div>
    <!-- </SCard> -->
</template>