<script setup>

const form = useFormData();
const step = useStep();
const { t, setLocale } = useI18n()


const possibleCountries = [
    { text: t('state_germany'), value: "de" },
    { text: t('state_austria'), value: "at" },
    { text: t('state_switzerland'), value: "ch" },    
    { text: t('state_france'), value: "fr" },
    { text: t('state_estonia'), value: "ee" },
    { text: t('state_sweden'), value: "se" },
    { text: t('state_italy'), value: "it" },
    { text: t('state_netherlands'), value: "nl" },
    { text: t('state_spain'), value: "es" },
    { text: t('state_portugal'), value: "pt" },
    { text: t('state_czech_republic'), value: "cz" },
    { text: t('state_slovakia'), value: "sk" },
    { text: t('state_hungary'), value: "hu" },
    { text: t('state_poland'), value: "pl" },
    { text: t('state_croatia'), value: "hr" },
    { text: t('state_slovenia'), value: "si" },
    { text: t('state_lithuania'), value: "lt" },
    { text: t('state_latvia'), value: "lv" },
    { text: t('state_finland'), value: "fi" },
    { text: t('state_denmark'), value: "dk" },
    { text: t('state_norway'), value: "no" },
    { text: t('state_bulgaria'), value: "bg" },
    { text: t('state_belgium'), value: "be" },
    { text: t('state_greece'), value: "el" },


];

let responseData = ref();
let isUpdating = ref(false);
let isNoWarranty = ref(false);
let isToastVisible = ref(false);
let errorToastMessage = ref("")

const isButtonEnabled = computed(() => {
    return form.value.productNumber && form.value.address.countryIso;
});

async function incStep() {
    if (step.value.currentStep < step.value.maxSteps) {
        const success = await submit();
        if (success) {
            // switch (form.value.countryIso) {
            //     case "en":
            //         setLocale("en-US");
            //     case "fr":
            //         setLocale("fr-FR");
            //     default:
            //         setLocale("de-DE");
            // }
            step.value.currentStep++;
        }
    }
}

async function submit() {
    try {
        isUpdating.value = true;
        isNoWarranty.value = false;

        const response = await fetch(`/api/getWarrentyExtensionForProduct?productNumber=${form.value.productNumber}&countryIso=${form.value.address.countryIso}`);
        responseData = await response.json();

        if (responseData === null, !response.ok) {
            // error
            errorToastMessage.value = responseData.message;
            if (response.status === 404) {
                errorToastMessage.value = t('product_not_found_error') + " service@scheppach.com.";
            }
            isToastVisible.value = true;
            return false;
        } else if (responseData.warrantyExtensionYears <= 0) {
            // no warranty
            isNoWarranty.value = true;
            console.log("No warranty left for this product");
            return false;
        } else {
            // has warranty
            form.value.productName = responseData.productName;
            form.value.maxRegistrationDays = responseData.maxRegistrationDays;
            // console.log("Warranty details:", responseData);
            return true;
        }
    } catch (error) {
        // error
        console.log("catch error")
        console.error("Error fetching warranty info:", error);
        errorToastMessage.value = t('undefined_error') + " service@scheppach.com.";
        isToastVisible.value = true;
        return false;
    } finally {
        isUpdating.value = false;
    }
}
</script>

<template>
    <SToast v-model:isVisible="isToastVisible" bgColor="#fee2e2" textColor="#dc2626" :text="errorToastMessage"
        :duration="6000" closeable />

    <!-- <SCard> -->
    <template v-if="isUpdating">
        <h2>{{ $t('loading') }}...</h2>
    </template>
    <template v-else-if="isNoWarranty">
        <div class="bg-blue-100 text-blue-800 text-center p-4 rounded-lg">
            <p class="font-semibold">{{ $t('no_warranty_for_product') }} {{ responseData.productName
            }} ({{ responseData.productNumber }}).</p>
        </div>
    </template>
    <template v-else>
        <section class="mb-8 h-full">
            <h3 class="text-xl font-semibold mb-3" style="color: #005188;">{{ $t("product_registration") }}</h3>
            <form @submit.prevent="submitForm" class="space-y-4">
                <!-- Artikelnummer -->
                <SNumberInput :label="$t('product_number')" v-model="form.productNumber" required />

                <SDropdown v-model="form.address.countryIso" :options="possibleCountries" :label="$t('state')"
                    :placeholder="$t('please_select')" required />

            </form>

            <div class="mt-8">
                <div class="flex flex-row justify-end">
                    <SButton @click="incStep" :disabled="!isButtonEnabled || isUpdating">
                        <SMdiIcon>arrow_forward</SMdiIcon>
                    </SButton>
                </div>
            </div>
        </section>
    </template>
    <!-- </SCard> -->
</template>

<style>
.lo {
    color: #dc2626
}
</style>